import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { api } from 'src/services'
import DataTable from 'react-data-table-component'
import CIcon from '@coreui/icons-react'
import { cilReload, cilX, cilListRich, cilInfo } from '@coreui/icons'

import {
  CAlert,
  // CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CButton,
  CInputGroup,
  CFormInput,
  CFormSelect,
  // CDropdown,
  // CDropdownToggle,
  // CDropdownMenu,
  // CDropdownItem,
  CListGroup,
  CListGroupItem,
  CTooltip,
} from '@coreui/react'

const customTooltipStyle = {
  '--cui-tooltip-max-width': '400px',
}

const columns = (clickHandler) => [
  {
    name: 'Support Ticket ID',
    sortable: true,
    selector: (row) => row.supportTicketId,
  },
  {
    name: 'Updated Time',
    sortable: true,
    selector: (row) => row.updated,
  },
  {
    name: 'User Email',
    sortable: true,
    selector: (row) => row.userEmail,
  },
  {
    name: 'Outcome',
    sortable: true,
    selector: (row) => row.aiGeneratedOutcome,
  },
  {
    name: 'Detail',
    cell: (row) => (
      <>
        <span onClick={() => clickHandler('detail', row)}>
          <CIcon icon={cilListRich} />
        </span>
        <CTooltip content={row.aiGeneratedSummary} style={customTooltipStyle}>
          <CIcon className="ms-2" icon={cilInfo} />
        </CTooltip>
      </>
      // <CDropdown>
      //   <CDropdownToggle color="white" caret={false}>
      //     <CIcon icon={cilOptions} />
      //   </CDropdownToggle>
      //   <CDropdownMenu>
      //     <CDropdownItem role="button" onClick={() => clickHandler('detail', row)}>
      //       Details
      //     </CDropdownItem>
      //     <CDropdownItem role="button" onClick={() => clickHandler('delete', row)}>
      //       Delete
      //     </CDropdownItem>
      //   </CDropdownMenu>
      // </CDropdown>
    ),
    ignoreRowClick: true,
    allowoverflow: true,
    button: true,
  },
]

const SubheaderComponent = ({
  filterText,
  filterType,
  filterTypes,
  filterStatus,
  filterStatuses,
  onFilter,
  onClear,
  onReload,
  onFilterType,
  onFilterStatus,
}) => (
  <>
    <CCol xs="auto" className="me-2">
      <CFormSelect
        type="text"
        id="filter-status"
        size="sm"
        label=""
        value={filterType}
        onChange={onFilterType}
        text=""
        required
      >
        <option value="">All Types</option>
        {filterTypes.map((type) => (
          <option
            key={type.value}
            value={type.value}
            selected={type.value === filterType ? 'selected' : ''}
          >
            {type.label}
          </option>
        ))}
      </CFormSelect>
    </CCol>
    <CCol xs="auto" className="me-2">
      <CFormSelect
        type="text"
        id="filter-status"
        size="sm"
        label=""
        value={filterStatus}
        onChange={onFilterStatus}
        text=""
        required
      >
        <option value="">All Statuses</option>
        {filterStatuses.map((status) => (
          <option
            key={status.value}
            value={status.value}
            selected={status.value === filterStatus ? 'selected' : ''}
          >
            {status.label}
          </option>
        ))}
      </CFormSelect>
    </CCol>
    <CInputGroup className="filter-input-group" size="sm">
      <CFormInput placeholder="Search" aria-label="Search" value={filterText} onChange={onFilter} />
      <CButton type="button" color="secondary" variant="outline" onClick={onClear}>
        <CIcon icon={cilX} />
      </CButton>
    </CInputGroup>
    <CButton className="reload-btn ms-2" color="light" size="sm" onClick={onReload}>
      <CIcon icon={cilReload} />
    </CButton>
    <CButton
      component="a"
      href="https://supportportal-api.truly.co/tickets?export=1"
      target="_blank"
      className="ms-2"
      color="light"
      size="sm"
    >
      Export CSV
    </CButton>
  </>
)

SubheaderComponent.propTypes = {
  filterType: PropTypes.string,
  filterTypes: PropTypes.array,
  filterStatus: PropTypes.string,
  filterStatuses: PropTypes.array,
  filterText: PropTypes.string,
  onFilter: PropTypes.func,
  onFilterType: PropTypes.func,
  onFilterStatus: PropTypes.func,
  onClear: PropTypes.func,
  onReload: PropTypes.func,
}

const Tickets = () => {
  const [loading, setLoading] = useState(false)
  const [tickets, setTickets] = useState([])
  const [filterText, setFilterText] = useState('')
  const [filterType, setFilterType] = useState('support')
  const [filterTypes, setFilterTypes] = useState([])
  const [filterStatus, setFilterStatus] = useState('Open')
  const [filterStatuses, setFilterStatuses] = useState([])
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const [visibleDetailDlg, setVisibleDetailDlg] = useState(false)
  const [detailTicket, setDetailTicket] = useState(null)

  const loadTypesAndStatuses = async () => {
    try {
      const response = await api.get('/ticket_filters')
      const types = response?.data?.type || []
      const statuses = response?.data?.status || []
      setFilterTypes(types)
      setFilterStatuses(statuses)
    } catch (error) {
      console.error(error)
      return error
    }
  }

  const loadTickets = async () => {
    try {
      setLoading(true)
      let params = {}
      if (filterType) {
        params.type = filterType
      }
      if (filterStatus) {
        params.status = filterStatus
      }
      console.log(params)
      const response = await api.get('/tickets', {
        params: params,
      })
      const tickets = response?.data || []
      setTickets(tickets)
      setLoading(false)
    } catch (error) {
      console.error(error)
      return error
    }
  }

  useEffect(() => {
    loadTypesAndStatuses()
    loadTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredItems = tickets.filter(
    (item) =>
      (item.id && item.id.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.supportTicketId &&
        item.supportTicketId.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.userEmail && item.userEmail.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.aiGeneratedOutcome &&
        item.aiGeneratedOutcome.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.aiGeneratedSummary &&
        item.aiGeneratedSummary.toLowerCase().includes(filterText.toLowerCase())),
  )

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    const reloadTickets = () => {
      loadTickets()
    }

    return (
      <SubheaderComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onFilterType={(e) => setFilterType(e.target.value)}
        onFilterStatus={(e) => setFilterStatus(e.target.value)}
        onClear={handleClear}
        onReload={reloadTickets}
        filterText={filterText}
        filterTypes={filterTypes}
        filterType={filterType}
        filterStatuses={filterStatuses}
        filterStatus={filterStatus}
      />
    )
  }, [filterText, filterType, filterTypes, filterStatus, filterStatuses, resetPaginationToggle])

  const handleButtonClick = async (action, data) => {
    if (action === 'detail') {
      setDetailTicket(data)
      setVisibleDetailDlg(true)
    }
  }

  useEffect(() => {
    loadTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus, filterType])

  const closeDetailDlg = () => {
    setVisibleDetailDlg(false)
  }

  return (
    <>
      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardHeader>Tickets</CCardHeader>
            <CCardBody>
              <DataTable
                progressPending={loading}
                columns={columns(handleButtonClick)}
                data={filteredItems}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                responsive={false}
                pagination
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CModal backdrop="static" size="lg" visible={visibleDetailDlg} onClose={closeDetailDlg}>
        <CModalHeader onClose={closeDetailDlg}>
          <CModalTitle>Detail</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {detailTicket && (
            <CListGroup flush>
              <CListGroupItem>
                <strong>Support Ticket ID</strong>: {detailTicket.supportTicketId}
              </CListGroupItem>
              <CListGroupItem>
                <strong>User Email</strong>: {detailTicket.userEmail}
              </CListGroupItem>
              <CListGroupItem>
                <strong>AI Generated Outcome</strong>: {detailTicket.aiGeneratedOutcome}
              </CListGroupItem>
              <CListGroupItem>
                <strong>AI Generated Summary</strong>:
                <div className="text-secondary mt-2">{detailTicket.aiGeneratedSummary}</div>
              </CListGroupItem>
              <CListGroupItem>
                <strong>Full Thread</strong>
                {detailTicket.fullThread.length > 0 && (
                  <div className="thread-container mt-2">
                    {detailTicket.fullThread.map((thread, index) => (
                      <CAlert color="secondary" key={`thread-${index}`}>
                        {thread.person} : {thread.chat}
                      </CAlert>
                    ))}
                  </div>
                )}
              </CListGroupItem>
            </CListGroup>
          )}
        </CModalBody>
      </CModal>
    </>
  )
}

export default Tickets
