import React from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'
import { useRoutePaths } from '../../hooks'
import { PrivateRoute } from '../PrivateRoute'
// import { PublicRoute } from '../PublicRoute'

// const Dashboard = React.lazy(() => import('../../views/dashboard/Dashboard'))
// const Tickets = React.lazy(() => import('../../views/tickets/List'))
import Tickets from '../../views/tickets/List'

function Router() {
  const {
    LOGIN_PATH,
    // REGISTER_PATH,
    ROOT_PATH,
    // DASHBOARD_PATH,
    TICKETS_PATH,
    // METRICS_PATH,
    // USERS_PATH,
    // USER_PATH
  } = useRoutePaths()

  return (
    <Routes>
      <Route path={ROOT_PATH} element={<Navigate to="tickets" replace />} />

      {/*<Route
        path={DASHBOARD_PATH}
        element={
          <PrivateRoute redirectTo={LOGIN_PATH}>
            <Dashboard />
          </PrivateRoute>
        }
      />*/}

      <Route
        path={TICKETS_PATH}
        element={
          <PrivateRoute redirectTo={LOGIN_PATH}>
            <Tickets />
          </PrivateRoute>
        }
      />

      {/*<Route
        path={USER_PATH}
        element={
          <PrivateRoute permissions={['dashboard']}>
            <Dashboard />
          </PrivateRoute>
        }
      />*/}

      {/*<Route
        path={METRICS_PATH}
        element={
          <PrivateRoute permissions={['metrics.list']} redirectTo={LOGIN_PATH}>
            <Metrics />
          </PrivateRoute>
        }
      />*/}

      {/*<Route
        path={USERS_PATH}
        element={
          <PrivateRoute permissions={['users.list', 'users.create']}>
            <Users />
          </PrivateRoute>
        }
      />*/}

      {/*<Route
        path={USER_PATH}
        element={
          <PrivateRoute permissions={['users.list', 'users.create']}>
            <Users />
          </PrivateRoute>
        }
      />*/}

      <Route path="*" element={<h1>404</h1>} />
    </Routes>
  )
}

export default Router
