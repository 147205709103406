import React from 'react'
import { Suspense } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorState } from '../../components/ErrorState'
import { Loader } from '../../components/Loader'
import { useSession } from '../../hooks'

function PublicRoute({ children }) {
  const { isAuthenticated } = useSession()

  if (isAuthenticated) {
    return <Navigate to="/" />
  }

  return (
    <ErrorBoundary fallback={<ErrorState text="An error occurred in the application." />}>
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </ErrorBoundary>
  )
}

PublicRoute.propTypes = {
  children: PropTypes.node,
}

export default PublicRoute
