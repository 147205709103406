import React from 'react'
import { Suspense } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorState } from '../../components/ErrorState'
import { Loader } from '../../components/Loader'
import { useSession } from '../../hooks'
import { validateUserPermissions } from '../../utils'

function PrivateRoute({ permissions, roles, redirectTo = '/login', children }) {
  const { isAuthenticated, user, loadingUserData } = useSession()
  const { hasAllPermissions } = validateUserPermissions({
    user,
    permissions,
    roles,
  })

  if (loadingUserData) {
    return null
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} />
  }

  if (!hasAllPermissions) {
    return <Navigate to="/" />
  }

  return (
    <ErrorBoundary fallback={<ErrorState text="An error occurred in the application." />}>
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </ErrorBoundary>
  )
}

PrivateRoute.propTypes = {
  permissions: PropTypes.array,
  roles: PropTypes.array,
  redirectTo: PropTypes.string,
  children: PropTypes.node,
}

export default PrivateRoute
