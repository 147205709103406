import React from 'react'
import PropTypes from 'prop-types'

const ErrorState = ({ text }) => {
  text = text ? text : 'An internal error occurred on the server'

  return <div>{text}</div>
}

ErrorState.propTypes = {
  text: PropTypes.string,
}

export default ErrorState
