import React from 'react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AuthContext } from '../../contexts'
import { paths } from '../../router'
import { api, setAuthorizationHeader } from '../../services'
import { createSessionCookies, getToken, removeSessionCookies } from '../../utils'

function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [loadingUserData, setLoadingUserData] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const token = getToken()
  // const token = 'sadfasdfasfasfdsaf'
  const isAuthenticated = Boolean(token)

  async function signIn(params) {
    const { username, password } = params

    try {
      const response = await api.post('/auth', { username, password })
      const { sessionToken } = response.data

      createSessionCookies({ token: sessionToken, refreshToken: '' })
      setUser({ username, permissions: [], roles: [] })
      setAuthorizationHeader({ request: api.defaults, token })
    } catch (error) {
      throw error
    }
  }

  function signOut() {
    removeSessionCookies()
    setUser(undefined)
    setLoadingUserData(false)
    navigate(paths.LOGIN_PATH)
  }

  useEffect(() => {
    if (!token) {
      removeSessionCookies()
      setUser(undefined)
      setLoadingUserData(false)
    }
  }, [navigate, pathname, token])

  // useEffect(() => {
  //   const token = getToken()

  //   async function getUserData() {
  //     setLoadingUserData(true)

  //     try {
  //       const response = await api.get('/me')

  //       if (response?.data) {
  //         const { email, permissions, roles } = response.data
  //         setUser({ email, permissions, roles })
  //       }
  //     } catch (error) {
  //       /**
  //        * an error handler can be added here
  //        */
  //       console.log('me error', error)
  //     } finally {
  //       setLoadingUserData(false)
  //     }
  //   }

  //   if (token) {
  //     setAuthorizationHeader({ request: api.defaults, token })
  //     getUserData()
  //   }
  // }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loadingUserData,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthProvider
