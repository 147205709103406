const ROOT_PATH = '/'
const LOGIN_PATH = '/login'
const REGISTER_PATH = '/register'
const DASHBOARD_PATH = '/dashboard'
const TICKETS_PATH = '/tickets'
// const METRICS_PATH = '/metrics'
// const USERS_PATH = '/users'
// const USER_PATH = '/users/:id'

const paths = {
  ROOT_PATH,
  LOGIN_PATH,
  REGISTER_PATH,
  DASHBOARD_PATH,
  TICKETS_PATH,
  // METRICS_PATH,
  // USERS_PATH,
  // USER_PATH
}

export default paths
